import request from '@/config/request.js'
import setting from '@/config/settings'
import common from '@/components/common/'
const ApiEvaluation = request.File
export default {
  components: common,
  name: 'del-file',
  data () {
    return {
      qiniu: setting.gbs.qiniu,
      paginations: {
        current_page: 1,
        total: 0,
        page_size: 10,
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      search_data: {
        filters: [{
          field: 'fileName',
          operator: 'like',
          value: ''
        }, {
          field: 'del',
          operator: 'eq',
          value: true
        }],
        current_page: 1,
        total: 0,
        page_size: 10,
      },
      tableList: [],
      categoryTable: [],
      multipleSelection: []
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 255
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    initData () {
      var data = {
        offset: (this.search_data.current_page - 1) * this.search_data.page_size,
        limit: this.search_data.page_size,
        data: {},
        filters: null,
        orFilters: null,
        order: '',
        sort: ''
      }
      data = Object.assign(data, ({
        filters: this.search_data.filters.filter(item => {
          if (item.value !== '') {
            return item
          }
        })
      } || {}))
      ApiEvaluation.pageFileDirectoryDetails.call(this, data, (listData) => {
        this.tableList = listData.content
        this.search_data.total = parseInt(listData.totalElements)
      })
    },
    updateExamPaperLife (type) {
      if (this.multipleSelection.length > 0) {
        let arr = []
        for (const item in this.multipleSelection) {
          console.log(item)
          arr = [...arr, this.multipleSelection[item].fileId]
        }
        if (type === 'del') {
          this.$confirm('确定删除选中的记录？, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            ApiEvaluation.deleteDirectoryFile.call(this, arr, res => {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.onSearch()
            })
          })
        } else {
          this.$confirm('确定还原选中的记录？, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            ApiEvaluation.batchUpdateFileDel.call(this, {
              del: false, fileids: arr
            }, res => {
              this.$message({
                type: 'success',
                message: '还原成功!'
              })
              this.onSearch()
            })
          })
        }
      } else {
        this.$message('请勾选需要操作的数据')
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    /**
     * 搜索事件
     */
    onSearch () {
      this.search_data.current_page = 1
      this.initData()
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    onChangeCurrentPage (page) {
      this.search_data.current_page = page
      this.initData()
    },
  },
  mounted () {
    this.initData()
  }
}
