// 登录
import Login from './login/index.vue'
// 404
import notFindComponent from './404/notFindComponent.vue'
// 首页
import Home from './home/'
// 智能测评
import Evaluation from './evaluation/'
// 文件管理
import File from './file/'

export default {
  Login,
  notFindComponent,
  Home,
  Evaluation,
  File
}
